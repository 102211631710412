/* global chrome */
import React, { useState, useEffect } from 'react';
const EXTENSION_ID = 'gfdbihpdllokhjfibpnnjeipanlcleje';

//TODO: check that method 3 requests cookies if not found, before continuing.



const exampleImageArray = ['https://i.ebayimg.com/images/g/t64AAOSwwOhgOn-i/s-l1600.jpg', 
'https://i.ebayimg.com/images/g/6eMAAOSwvZRgOn-s/s-l960.jpg',
'https://i.ebayimg.com/images/g/c94AAOSwOVhgOn-y/s-l960.jpg'
];
const dummyProduct = {
  images: exampleImageArray,
  brand: 'Haute Hippie',
  colors: ['Gray'],
  category: {
    category1: 'Women',
    category2: 'Sweaters',
    category3: 'Crew & Scoop Necks'
  },
  condition: 'not_nwt',
  description: 'DEMO Product: Haute Hoodie Sweater Gray\nDO NOT BUY\nSize: Medium',
  title: 'DEMO Product: Haute Hoodie Sweater Gray DO NOT BUY',
  original_price: '120',
  price: '99',
  sku: 'SKU100',
  size: 'M'
}




const ExternalServer = ({ setDataArray, setMessageArray, poshmarkCookies, setPoshmarkCookies }) => {
  const [isWaitingForCookies, setIsWaitingForCookies] = useState(false);
  const [newListingId, setNewListingId] = useState(null);

  useEffect(() => {
    const checkCookies = () => {
      if (Object.keys(poshmarkCookies).length !== 0) {
        setIsWaitingForCookies(false); 
        sendCookiesAPI();
      }
    };

    if (isWaitingForCookies) {
      const intervalId = setInterval(checkCookies, 500); // Check every 500 milliseconds

      // Cleanup function to clear interval
      return () => clearInterval(intervalId);
    }
  }, [poshmarkCookies, isWaitingForCookies]);


  async function sendCookiesAPI() {
    console.log('Send Cookies API action');

    if (Object.keys(poshmarkCookies).length === 0) {
      setIsWaitingForCookies(true);
      setMessageArray(prevArray => [...prevArray, {messageStatus: 'warning', message: 'Poshmark cookies not set. Attempting to capture...'}]);

      sendMessageToExtension({
        action: 'savePoshmarkCookies', 
        data: {},
        saveData: setPoshmarkCookies
      });
      
      return;
    }

    // Assuming poshmarkCookies are valid, proceed
    setMessageArray(prevArray => [...prevArray, {messageStatus: 'info', message: 'External action started with valid cookies.'}]);

    const sellData = {
      cookies: poshmarkCookies,
      listingData: dummyProduct
    }
    
    try {
      const response = await fetch('https://api.flippertools.com/cookies', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sellData) 
      });

      if(!response.ok){
        throw new Error(`'HTTP Error: ${response.status}`);
      }

      const jsonResponse = await response.json();
      console.log('Success:', jsonResponse);
      setNewListingId(jsonResponse?.response?.listingId ?? null);
      setDataArray(prevArray => [...prevArray, {action: 'ExternalServer', data: jsonResponse.response}]);
      setMessageArray(prevArray => [...prevArray, {messageStatus: 'success', message: 'External action successful.'}]);

    } catch (error) {
      console.error('Fetch Error:', error);
      setMessageArray(prevArray => [...prevArray, {messageStatus: 'danger', message: `Fetch Error: ${error}`}]);
    }



  }

  return (
    <div class="container">
      <h3>Method 3: Extension Sends Cookie, External Server with puppeteer</h3>
      <p>Uses Extension to grab Poshmark cookies, send to Heroku Node.js server. Uses puppeteer for headless Chrome interactions. Currently ~12 seconds with delay for turning photos into square blobs.</p>
      <button id='cookies' onClick={sendCookiesAPI} className='btn btn-info' style={{marginRight: '3px'}}>
        Send Cookies External
      </button>
      {newListingId && (
        <ExtensionActionButton key={'deleteMethod3'} id={'deleteMethod3'} action={'deletePoshmarkListing'} data={newListingId} buttonClass={'danger'} >
          Delete Product
        </ExtensionActionButton>
      )}

    </div>
  )
}

const OtherActions = ({dataArray}) => {
  const [poshmarkListingId, setPoshmarkListingId] = useState(null);

  useEffect(() => {
    console.log('change to id - ', poshmarkListingId);
  }, [poshmarkListingId]);

  const poshDummyProductArray = [{ id: 'pNewListing-tab', action: 'poshmarkListingTab', data: dummyProduct},
  { id: 'pNewListing-window', action: 'poshmarkListingWindow', data: dummyProduct}]

  useEffect(() => {
    console.log('useEffect dataArray - ', dataArray);

    const targetObject = dataArray.reduceRight((accumulator, obj) => {
      if (accumulator) return accumulator;
      if ((obj.action === "poshmarkListingTab" || obj.action === "poshmarkListingWindow") && obj.data) {
        return obj; 
      }
      return null;
    }, null); 

    if (targetObject && targetObject.data) {
      console.log('target object found - ', targetObject); 
      console.log('targetObject.data.poshmarkListingId - ', targetObject.data.listingId);
      console.log('targetObject.data - ', targetObject.data);
      setPoshmarkListingId(targetObject.data.listingId); // Ensure the property name matches exactly
    }
  }, [dataArray]);

  return (
    <div className='container'>
      <h3>Method 2: Add Product by Chrome Extension New Tab Actions</h3>
      <p>Opens tab/window, creates product sent from this page. New tab ~35 seconds, new window ~7 seconds.</p>
        
      {poshDummyProductArray.map((btn) => (
        <ExtensionActionButton key={btn.id} id={btn.id} action={btn.action} data={btn.data} buttonClass={'info'} >
          {btn.id}
        </ExtensionActionButton>
      ))}
      {/* Conditionally render the Delete Listing button */}
      {poshmarkListingId && (
        <ExtensionActionButton key={'delete'} id={'delete'} action={'deletePoshmarkListing'} data={poshmarkListingId} buttonClass={'danger'} >
          Delete Product
        </ExtensionActionButton>
      )}

    </div>
  )
}

const sendMessageToExtension = ({ action, data, setIsActive = () => {}, saveData = () => {} }) => {
  //TODO: saveData not used (sends message out, other code to receive)

  window.postMessage({ action, data, fromWebpage: true }, "*");

  if (action === 'stopAction') {
      setIsActive(false);
  } else if (action === 'actionRestart') {
      setIsActive(true);
  }
};

const ExtensionActionButton = ({ id, action, children, data = null, buttonClass, setIsActive = () => {}, saveData = () => {} }) => {
  
  const handleClick = () => {
    console.log('handle click ExtensionActionButton saveData - ', saveData);
    sendMessageToExtension({ action, data, setIsActive, saveData });
  };

  return (
    <>
      <button id={id} onClick={handleClick} className={`btn btn-${buttonClass}`} style={{marginRight: '3px'}}>
        {children}
      </button>
    </>
  );
};

const MercariButtons = ({dataArray, setMessageArray}) => {
  //deleteLastMercari and activeListings has 401 unauthorized even with token
  const [lastMercariId, setLastMercariId] = useState(null);

  const mercariButtonArray = [
    { id: 'saveCookies', action: 'saveMercariCookies', buttonClass: 'primary' },
    { id: 'initalizeMercari', action: 'initalizeMercari', buttonClass: 'primary' }
  ]

  // save the mercari listing ID for use in delete
  useEffect(() => {
    const reversedDataArray = [...dataArray].reverse();
    const foundItem = reversedDataArray.find(item =>
      item.action === 'createMercariListingTab' || item.action === 'createMercariListingWindow'
    );

    if (foundItem) {
      setLastMercariId(foundItem.data.listingId);
    }
  }, [dataArray]);

  function initializeTokenData(actionName){
    const reversedArray = dataArray.slice().reverse();
    let initializeData = null;
    for (const item of reversedArray) {
      console.log('item - ', item);
      if (item.action === "initalizeMercari") {
        initializeData = item.data;
        break; // Stop searching once a match is found
      }
    }

    if (!initializeData) {
      setMessageArray(prevData => [...prevData, {messageStatus: 'danger', message: 'Use initializeData first. No tokens found.'}]);
      console.log("No initializeData found");
      return; // Exit if no data was initialized
    }

    if(actionName === 'mercariDeleteListing'){
      if(lastMercariId){
        initializeData.listingId = lastMercariId;
      }else{
        setMessageArray(prevData => [...prevData, {messageStatus: 'danger', message: 'No listing ID find. Create new listing first.'}]);
        console.log("No lastMercariId found");
        return;
      }
    }
  


    sendMessageToExtension({
      action: actionName, 
      data: initializeData
    });
  }

  const mercariDummyProduct = [{ id: 'mNewListing-tab', action: 'createMercariListingTab', data: dummyProduct},
   { id: 'mNewListing-window', action: 'createMercariListingWindow', data: dummyProduct}]
  

  return(
    <div className="container" style={{marginBottom: '5px'}}>
      <p>activeListings has 401 Unauthorized, capture tokens (intialize) first still failing. </p>
      <p>New Listings can be started; tab is slow/error prone; new window ~11 seconds</p>
      {mercariButtonArray.map((btn) => (
        <ExtensionActionButton key={btn.id} id={btn.id} action={btn.action} data={btn.data} buttonClass={btn.buttonClass} >
          {btn.id}
        </ExtensionActionButton>
      ))}
      <button id='activeListings' onClick={() => initializeTokenData('mercariActiveListings')}  className='btn btn-primary' style={{marginRight: '3px'}}>
        activeListings
      </button>
      {mercariDummyProduct.map((btn) => (
        <ExtensionActionButton key={btn.id} id={btn.id} action={btn.action} data={btn.data} buttonClass={'info'} >
          {btn.id}
        </ExtensionActionButton>
      ))}

    {
      lastMercariId && (
        <>
          <button id='deleteLastMercari' onClick={() => initializeTokenData('mercariDeleteListing')} className='btn btn-danger' style={{marginRight: '3px'}}>
            deleteLastMercari
          </button>
          <ExtensionActionButton key='mercariDeleteWindow' id='mercariDeleteWindow' action='mercariDeleteWindow' data={lastMercariId} buttonClass='danger' >
            mercariDeleteWindow
          </ExtensionActionButton>
        </>
      )
    }


    </div>
  )

}

const ButtonLogic = (setPoshmarkCookies) => {
  const [isPoshmarkActive, setIsPoshmarkActive] = useState(true);

  const poshmarkButtons = [
    { id: 'saveCookies', action: 'savePoshmarkCookies', buttonClass: 'primary', saveData: setPoshmarkCookies },
    { id: 'requestCloset', action: 'requestPoshmarkClosetAvailable', buttonClass: 'primary' },
    { id: 'requestLikes', action: 'requestPoshmarkLikes', buttonClass: 'primary' },
    { id: 'sharingStart', action: 'sharePoshmarkListings', buttonClass: 'primary' },
    { id: 'catalog', action: 'poshmarkCatalog', buttonClass: 'primary' },
    { id: 'draft', action: 'requestDraftId', buttonClass: 'primary' },
    { id: 'photo', action: 'poshmarkSavePhoto', buttonClass: 'primary' },
    { id: 'token', action: 'tokenTest', buttonClass: 'primary' },
    { id: 'sold', action: 'requestPoshmarkSold', buttonClass: 'primary' },
    ...(isPoshmarkActive ? [{ id: 'Stop', action: 'stopAction', buttonClass: 'danger' }] : [{ id: 'Start', action: 'actionRestart', buttonClass: 'success' }]),
  ].map(btn => ({
    ...btn,
    // Conditionally add `saveData` only for 'savePoshmarkCookies' action
    saveData: btn.action === 'savePoshmarkCookies' ? setPoshmarkCookies : null
  }));


  return(
    <div className="container" style={{marginBottom: '5px'}}>
      <h3>Method 1: API Requests from Chrome Extension</h3>
      <p>Capturing cookies & making API requests only goes so far. Cannot figure out uploading photos. 403 Forbidden at endpoint, even with CSRF token.</p>
      {poshmarkButtons.map((btn) => (
        <ExtensionActionButton key={btn.id} id={btn.id} action={btn.action} buttonClass={btn.buttonClass} setIsActive={setIsPoshmarkActive} saveData={btn.saveData}>
          {btn.id}
        </ExtensionActionButton>
      ))}
    </div>
  )
}

const MessageAlerts = ({ messageArray }) => {
  if (messageArray.length === 0) {
    return null; 
  }

  return (
    <div style={{ maxHeight: '200px', maxWidth: '500px', marginTop: '10px', marginBottom: '10px', overflowY: 'auto' }}>
      {messageArray.slice(0).reverse().map((msg, index) => (
        <div key={index} className={`alert alert-${msg.messageStatus}`} role="alert">
          {msg.message}
        </div>
      ))}
    </div>
  );
};

const DataDisplay = ({ dataArray }) => {
  if (dataArray.length === 0) {
    return null;
  }

  return (
    <div style={{ maxHeight: '500px' }}>
      {dataArray.slice(0).reverse().map((data, index) => (
        <div 
          key={index} 
          style={{ 
            maxHeight: '200px', 
            maxWidth: '900px',
            overflowY: 'auto', 
            marginBottom: '10px', 
            padding: '10px', 
            border: '1px solid #ccc', 
            borderRadius: '5px', 
            backgroundColor: '#f8f9fa'
          }}
        >
          <pre>{JSON.stringify(data.data, null, 2)}</pre>
        </div>
      ))}
    </div>
  );
};



const HomePage = () => {
  const [extensionInstalled, setExtensionInstalled] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [messageArray, setMessageArray] = useState([]);
  const [poshmarkCookies, setPoshmarkCookies] = useState({});


  // useEffect(() => {
  //   if (dataArray.length > 0) {
  //     const foundItem = dataArray.slice().reverse().find(item => item.action === 'savePoshmarkCookies');
  //     if (foundItem) {
  //       setPoshmarkCookies(foundItem.data); // Update poshmarkCookies with the found data
  //     }
  //   }
  // }, [dataArray]);



  // CHECK for extension installed by having extension add attribute to page;
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes" && mutation.attributeName === "data-extension-installed") {
          const isInstalled = document.body.getAttribute('data-extension-installed') === 'true';
          console.log('extension ' + (isInstalled ? 'found' : 'not found'));
          setExtensionInstalled(isInstalled);
        }
      });
    });
  
    observer.observe(document.body, {
      attributes: true // Configure observer to listen for attribute changes
    });
  
    // Perform an initial check in case the attribute is already set before the observer starts
    const isInstalled = document.body.getAttribute('data-extension-installed') === 'true';
    if (isInstalled) {
      console.log('extension found');
      setExtensionInstalled(true);
    }
  
    // Disconnect the observer when the component unmounts
    return () => observer.disconnect();
  }, []);





  //RECEIVE messages from extension;
  React.useEffect(() => {
    const handleExtensionResponse = (event) => {
      if (event.source === window && event.data && event.data.fromExtension) {
        console.log("WEBPAGE Received response from extension:", event.data);

        if(event.data.data){
          setDataArray(currentArray => [...currentArray, {action: event.data.action, data: event.data.data}]);
          if(event.data.action === 'savePoshmarkCookies'){
            setPoshmarkCookies(event.data.data)
          }
        }

        if(event.data.message){
          setMessageArray(currentArray => [...currentArray, {messageStatus: event.data.messageStatus, message: event.data.message}]);
        }
      };
    };
  
    window.addEventListener("message", handleExtensionResponse);
    return () => {
      window.removeEventListener("message", handleExtensionResponse);
    };
  }, []); 



  return (
    <div className="container" style={{marginBottom: '100px'}}>
      {extensionInstalled ? (
        <>
          <div className="container">
            <p>Extension is installed</p>
          </div>
          
          <div className="container">
            <h1>Poshmark Website</h1>
              <ButtonLogic 
                setPoshmarkCookies={setPoshmarkCookies}
              />
              <OtherActions 
                dataArray={dataArray}
              />
              <ExternalServer 
                setDataArray={setDataArray}
                setMessageArray={setMessageArray}
                poshmarkCookies={poshmarkCookies}
                setPoshmarkCookies={setPoshmarkCookies}
              />
            <h1>Mercari Website</h1>
              <MercariButtons dataArray={dataArray} setMessageArray={setMessageArray}/>
              <MessageAlerts messageArray={messageArray} />
              <DataDisplay dataArray={dataArray} />
          </div>

        </>
      ) : (
        <div>
          <p>Extension is NOT installed.</p>
          <a href="#" target="_blank" rel="noopener noreferrer">Click here to install the extension.</a>
        </div>
      )}
    </div>
  );
};

export default HomePage;
